<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    type?: 'info' | 'warning' | 'error'
    dismissible?: boolean
    icon?: string
    iconSize?: number
    inheritColor?: boolean
  }>(),
  {
    type: 'info',
    dismissible: false,
    iconSize: 24,
  }
)

const emit = defineEmits<{
  (e: 'dismiss'): void
}>()

const isShown = defineModel<boolean>({ default: true })

watch(isShown, newValue => {
  if (!newValue) {
    emit('dismiss')
  }
})

const computedClasses = computed(() => [
  `alert--${props.type}`,
  `text-${props.inheritColor ? props.type : 'grey-darker'}`,
])

const computedIcon = computed(() => {
  if (props.icon) {
    return props.icon
  } else if (props.type === 'info') {
    return 'custom:info'
  }
  return null
})
</script>

<template>
  <v-alert v-model="isShown" class="alert" :class="computedClasses" :icon="false" :closable="dismissible">
    <div class="alert__content">
      <v-icon v-if="computedIcon" :size="iconSize">
        {{ computedIcon }}
      </v-icon>
      <div class="alert__text">
        <slot />
      </div>
      <slot name="action" />
    </div>
  </v-alert>
</template>

<style lang="sass" scoped>
.alert
  border-radius: 8px
  &--info
    background-color: rgba(181, 213, 247, 0.15)
  &--error
    background-color: #FFDCE7
  &--warning
    background-color: #FFF3EB
  &__content
    display: flex
    align-items: center
    > * + *
      margin-left: 1rem
  &__text
    flex: 1
  :deep(.v-icon), :deep(.v-btn--icon)
    transition: none
    color: inherit
</style>
